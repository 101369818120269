import FourOFourPage from 'components/FourOFourPage';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const NotFoundError = () => <FourOFourPage />;

export const getInitialProps = async (ctx: any) => {
  let props = {};
  if (ctx?.locale) {
    props = {
      ...(await serverSideTranslations(ctx.locale, ['common'])),
    };
  }
  return {
    props,
  };
};

export default NotFoundError;
